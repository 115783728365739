import React from 'react';
import CommonPortfilo from './CommonPortfilo';

const Portfilo = () => {
    return (
        <div>
            <CommonPortfilo items={10} condition={true}/>
        </div>
    );
};

export default Portfilo;