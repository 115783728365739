import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
import work10 from "../assets/images/work_images/10.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
import workSmall10 from "../assets/images/work_images/small/10.jpg";
// blog post images
import blog1 from "../assets/images/blog_images/1.jpg";
// blog image small
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
// slider images
import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
// icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";

// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(true);
      localStorage.setItem("theme", "dark");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Software",
      title: "Litho 3D",
      img: work1,
      imgSmall: workSmall1,
      bg: "#F4F4FF",
      projectType: "Personal Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/Litho3D",
      langages: "C++, OpenGL, GLFW, Assimp, Imgui",
      liveLink: "https://litho-3d.ljsp.fr",
      liveText: "Here (WIP)",
      description:
      "A project originally made to create 3D Lithophanes from images, for now more a 3D model viewer. I started this project by following the course Computer Graphics with Modern OpenGL and C++ on Udemy and then added more features. I plan on porting it to the web with emscripten."
    },
    {
      id: "2",
      tag: "Web",
      title: "Portfolio",
      img: work2,
      imgSmall: workSmall2,
      bg: "#E9FAFF",
      projectType: "Personal Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/portfolio",
      langages: "HTML, CSS, JS, TailwindCSS, ThreeJS",
      liveLink: "https://portfolio.ljsp.fr",
      liveText: "Here (WIP)",
      description:
        "You should be on this one right now but with all the work, I decided to use a quicker solution (this portfolio) and work at my pace. This portfolio main feature is an Impossible Box inspired by the ones in the games Antichamber and also Moncage that I discovered afterwards. Its achieved using ThreeJSs stencil and the goal was to present myself and my work in a fun way by exploring the different rooms of the cube while improving my skills in ThreeJS and web development.",
    },
    {
      id: "3",
      tag: "Web",
      title: "Lamp Visualiser",
      img: work3,
      imgSmall: workSmall3,
      bg: "#E9FAFF",
      projectType: "Personal Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/lamp-visualiser",
      langages: "HTML, CSS, JS, TailwindCSS, ThreeJS",
      liveLink: "https://lamp-visualiser.ljsp.fr",
      liveText: "Here",
      description:
        "As I'm a big fan of 3D printing, I've created many lithophane lamps for family and friends. I wanted to create a tool to easily see how the final lamps would look like. This site is only for visualisation as the object is simply a deformed plane. It was also a good way to learn ThreeJS and improve my skills in web development.",
    },
    {
      id: "4",
      tag: "Software",
      title: "TinyMesh",
      img: work4,
      imgSmall: workSmall4,
      bg: "#F4F4FF",
      projectType: "School Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/TinyMesh",
      langages: "C++, OpenGL, Qt, SDF, Bezier",
      liveLink: "",
      liveText: "",
      description:
        "This project is a fork of a mesher made by my teacher for the Modelisation course. I've implemented many different shapes, either parametric or implicit using SDFs (Signed Distance Fields). There is also the possibility to create a mesh from a given heightmap and deform it into a sphere. I've also implemented Bezier curves and surfaces to create more complex shapes that can be twisted.",
    },
    {
      id: "5",
      tag: "Embedded",
      title: "Project : IDEFIX",
      img: work5,
      imgSmall: workSmall5,
      bg: "#FFFAE9",
      projectType: "School Project",
      github: "None",
      linkGithub: "None",
      langages: "C++, Python, OpenCV, Raspberry Pi, Lego Mindstorm EV3",
      liveLink: "",
      liveText: "None",
      description:
        "Creation of a robot that plays football using Lego Mindstorm EV3. The robot is controlled by the EV3 Brick and a Raspberry Pi 3B+ processes a live fead of the playing area to analyse it and help the robot take actions. The robot is able to detect the ball using infrared receptors and the goal and to move towards it and position itself to shoot. The robot is programmed in C++ and the Raspberry Pi in Python.",
    },
    {
      id: "6",
      tag: "Software",
      title: "GAM",
      img: work6,
      imgSmall: workSmall6,
      bg: "#F4F4FF",
      projectType: "School Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/Mesh_Computer_Geometry",
      langages: "C++, OpenGL, Qt, Laplacian, Mesh Simplification",
      liveLink: "",
      liveText: "",
      description:
        "A project to implement different algorithms to modify a mesh. It can load objects in the OFF file format and can apply different algoriths such as Laplacian. The project is made using Qt and OpenGL.",
    },
    {
      id: "7",
      tag: "Research",
      title: "POR: Decroissance numerique",
      img: work7,
      imgSmall: workSmall7,
      bg: "#FFF0F8",
      projectType: "School Project",
      github: "Here",
      linkGithub: "https://github.com/ljsp/POR_Decroissance_Numerique",
      langages: "Python, Linux, Memory Consumption, Virtual Memory",
      liveLink: "",
      liveText: "",
      description:
        "The world uses more and more energy and the digital world is no exception. There's specially a rise in the amount of data that is produced and stored. This project is a research project to find how much memory do our daily application use. The task seems simple but as modern os use virtual memory, it's hard to know how much memory is actually used. We decided to make our tests on Linux as its the most open OS and Python for our mesuring tools.",
    },
    {
      id: "8",
      tag: "Image Processing",
      title: "Edge Detection",
      img: work8,
      imgSmall: workSmall8,
      bg: "#FFF0F0",
      projectType: "School Project",
      github: "",
      linkGithub: "",
      langages: "C++, OpenCV",
      liveLink: "",
      liveText: "",
      description:
        "A small project to implement the Region Growing algorithm to detect edges in an image.",
    },
    {
      id: "9",
      tag: "Web",
      title: "Les Pêcheurs Ardéchois",
      img: work9,
      imgSmall: workSmall9,
      bg: "#E9FAFF",
      projectType: "Personal Project",
      github: "",
      linkGithub: "",
      langages: "HTML, CSS, JS, Sass",
      liveLink: "https://www.lespecheursardechois.com",
      liveText: "Here",
      description:
        "A website I made for my cousin's fishing activity. It's a simple website with a few pages and a contact form. I used Sass to make the CSS easier to write and to make it more modular.",
    },
    {
      id: "10",
      tag: "Game",
      title: "The Hacker",
      img: work10,
      imgSmall: workSmall10,
      bg: "#FCF4FF",
      projectType: "School Project",
      github: "Here",
      linkGithub: "https://forge.univ-lyon1.fr/p1908367/jeu-ptut-gdx",
      langages: "Java, LibGDX, Python",
      liveLink: "",
      liveText: "",
      description:
        "The Hacker is a group project made during my DUT in computer science. It's a 2D game where you play as a hacker that has to infiltrate a building to steal data. The game is made using LibGDX and the map is made using a custom tile editor. I was in charge of the map creation and programming some functionalities and mini-game.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "TBD",
      category: "Future Project",
      title: "Coming Soon",
      bg: "#FCF4FF",
      description:
        "Placeholder for future project.",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // serviceArray items for about page
  const serviceArray = [
    {
      id: "1",
      icon: icon,
      title: "Software Development",
      des: "I like to develop softwares to create tools that can help in 3D Printing and to understand how things work.",
      color: "#D566FF",
      bg: "#FFFFFF",
    },
    {
      id: "2",
      icon: icon1,
      title: "Web Development",
      des: "The web is the easiest place to share stuff. I like to create websites to show my work and bring the use of my projects online.",
      color: "#DDA10C",
      bg: "#FFFFFF",
    },
    {
      id: "3",
      icon: icon2,
      title: "Video Games",
      des: "Video games are what got me into computer science. I would really like to understand how they work and how they are made.",
      color: "#8774FF",
      bg: "#FFFFFF",
    },
    {
      id: "4",
      icon: icon3,
      title: "3D Printing & Modeling",
      des: "Using Fusion 360 and Blender, I like to create useful or pretty 3D models and then print them to see how they look in real life.",
      color: "#FF6080",
      bg: "#FFFFFF",
    },
    {
      id: "5",
      icon: icon4,
      title: "Rock Climbing",
      des: "I've been climbing for 8 years now and I love it, especially in the nature. Its a great way to relax and to push myself to my limits.",
      color: "#FF75D8",
      bg: "#FFFFFF",
    },
    {
      id: "6",
      icon: icon5,
      title: "Mangas",
      des: "Mangas migth seem childish but I believe they can be a great source of inspiration and many of them are masterpieces.",
      color: "#FF75D8",
      bg: "#FFFFFF",
    },
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "2022-2024",
      title: "Master degree focused on Imaging, Development and 3D Technologies",
      place: "Université Claude Bernard Lyon 1, France",
      bg: "#FFF4F4",
    },

    {
      id: 2,
      date: "2021 - 2022",
      title: "Bachelor degree in Computer Science",
      place: "Université Claude Bernard Lyon 1, France",
      bg: "#FFF4F4",
    },

    {
      id: 3,
      date: "2019 - 2021",
      title: "DUT in Computer Science",
      place: "Université Claude Bernard Lyon 1, France",
      bg: "#FFF4F4",
    },
  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "2021",
      title: "Precia Molen Internship",
      place: "Worked on automating the tests for a wheighing machine interface.",
      bg: "#EEF5FA",
    },
  ];
  // awardsArray items for Resume page
  const awardsArray = [
    {
      id: 1,
      date: "2021",
      title: "TOEIC Certificate",
      place: "950 / 990",
      bg: "#FCF9F2",
    },

    {
      id: 2,
      date: "2019",
      title: "Nuit de l'info 2019 : Won the main event",
      place: "A national competition about creating a website in one night",
      bg: "#FCF9F2",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "C/C++",
      number: "80",
      value: "Strong",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Java",
      number: "60",
      value: "Intermediate",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "HTML/CSS/JS",
      number: "65",
      value: "Intermediate",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Python",
      number: "65",
      value: "Intermediate",
    },
  ];

  const lineArray1 = [
    {
      id: "01",
      color: "#FF6464",
      name: "French",
      number: "100",
      value: "Native",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "English",
      number: "80",
      value: "C1",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "German",
      number: "50",
      value: "A2/B1",
    }
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+33 6 83 35 23 10",
      item2: "",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "contact@ljsp.fr",
      item2: "",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "47bis Cours Tolstoï, 69100",
      item2: "Villeurbanne, France",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    awardsArray,
    lineArray,
    lineArray1,
    contactArray,
  };
};

export default AllData;
